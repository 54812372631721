import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import translate from '../../data/translations';

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: 16,
    letterSpacing: '0.8px',
    lineHeight: '18px',
    fontWeight: 600,
    // backgroundColor: '#0684FA',
    backgroundImage: '-webkit-linear-gradient(180deg, #8656da, #0082f9)',
    fontFamily: 'Open Sans, sans-serif',
    textTransform: 'capitalize',
    // boxShadow: '-3px 3px 6px #00000029',
    color: '#fff',
    width: 150,
    minWidth: 150,
    padding: '9px 15px',
    borderRadius: '45px',
    '&:hover': {
      // backgroundColor: '#0060BF'
      backgroundImage: '-webkit-linear-gradient(180deg, #764dbd, #026cce)'
    }
  }
}));

const SignupButton = ({ onClick, lang, label }) => {
  const classes = useStyles();

  return (
    <Button
      data-name="navbar-signup-btn"
      data-type="btn-click"
      onClick={onClick}
      className={classes.button}
      id="signUpButton"
    >
      {label || translate('signUp', lang, 'Sign Up')}
    </Button>
  );
};

SignupButton.propTypes = {
  onClick: PropTypes.func,
  lang: PropTypes.string,
  label: PropTypes.string
};

SignupButton.defaultProps = {
  onClick: () => {},
  lang: '',
  label: null
};

export default SignupButton;
